<template>
  <header class="header">Header</header>
</template>

<script>
export default {
  name: "TheHeader",
};
</script>

<style scoped></style>
